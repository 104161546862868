<template>
    <b-card
      class="card-transaction"
      no-body
    >
    <b-overlay
      :show="loading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
    >
      <b-card-header>
        <div>
            <b-card-title class="sub-heading">Invoices</b-card-title>
            <b-card-text class="font-small-2">
                {{ type }} Invoices Overview
            </b-card-text>
        </div>
  
        <div>
          <b-dropdown
          :text="type"
          variant="outline-secondary"
        >
        <b-dropdown-item href="#" @click="()=>getMonthlyReport()">
          Monthly
        </b-dropdown-item>
        <b-dropdown-item href="#" @click="()=>getWeeklyReport()">
          Weekly
        </b-dropdown-item>
        </b-dropdown>
        </div>
      </b-card-header>
  
      <b-card-body>
        <div class="row align-items-center">
            <div class="col-4">
              <div class="d-flex align-items-center">
                <div>
                  <h1 class="heading-text">
                    {{ invoices.total }}
                  </h1>
                </div>
                  <!-- <div>
                    <b-badge class="d-inline-block ml-1" variant="light-success">+4.2%</b-badge> 
                  </div> -->
                </div>
                 <p class="text-simple">Total invoices processed this {{ type=='Weekly'?'week':'year' }} </p>
            </div>
            <div class="col-8">
                <vue-apex-charts
                ref="myChart"
                type="bar"
                height="200"
                :options="chartOptions"
                :series="salesBar.series"
              />
            </div>

        </div>
        <div class="row border rounded py-1 mt-2">
            <div class="col-4">
              <div class="d-flex align-items-center">
                <b-avatar
                  rounded
                  size="30"
                  :variant="`light-secondary`"
                >
                  <feather-icon
                    size="18"
                    icon="ClockIcon"
                  />
                </b-avatar>
                <span class="label-text">
                  Pending
                </span>
              </div>
                  <div class="amount-text my-8">{{invoices.unpaid}}</div>
                  <b-progress
                    :value="invoices.unpaid/invoices.total*100"
                    max="100"
                    height="4px"
                    variant="secondary"
                    />
            </div>
            <div class="col-4">
              <div class="d-flex align-items-center">
                <b-avatar
                    rounded
                    size="30"
                    :variant="`light-success`"
                  >
                  <feather-icon
                    size="18"
                    color="info"
                    icon="PieChartIcon"
                  />
                </b-avatar>
                <span class="label-text">Sent</span>
              </div>
                  <div class="amount-text my-8">{{invoices.total}}</div>
                  <b-progress
                    :value="invoices.total/invoices.total*100"
                    max="100"
                    height="4px"
                    variant="success"
                    />
            </div>
            <div class="col-4">
              <div class="d-flex align-items-center">
                <b-avatar
                    rounded
                    size="30"
                    :variant="`light-primary`"
                  >
                    <feather-icon
                      size="18"
                      color="primary"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                  <span class="label-text">Paid</span>
              </div>
                  <div class="amount-text my-8">{{invoices.paid}}</div>
                  <b-progress
                    :value="invoices.paid/invoices.total*100"
                    max="100"
                    height="4px"
                    variant="primary"
                    />
            </div>
        </div>
      </b-card-body>
    </b-overlay>
    </b-card>
  </template>
  
  <script>
  import {
    BOverlay,BCard, BCardHeader,BBadge, BCardTitle,BCardText, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,BProgress
  } from 'bootstrap-vue'
  import { $themeColors } from '@themeConfig'
  import VueApexCharts from 'vue-apexcharts'
  import {get_invoice_reports} from '@/apis/admin/dashboard'
  export default {
    components: {
      BCard,
      BCardHeader,
      BCardTitle,
      BCardBody,
      BMediaBody,
      BMedia,
      BMediaAside,
      BAvatar,
      BDropdown,
      BDropdownItem,
      VueApexCharts,
      BCardText,
      BProgress,
      BBadge,
      BOverlay
    },
    data() {
      return {
        salesBar: {
            series: [
                {
                name: 'Invoices',
                data: [75, 125, 225, 175, 125, 75, 25,40],
                },
            ],
        },
        chartOptions: {
          chart: {
            sparkline: { enabled: true },
            toolbar: { show: false },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
            },
          },
          states: {
            hover: {
              filter: 'none',
            },
          },
          colors: [
            '#ebf0f7',
            '#ebf0f7',
            '#ebf0f7',
            '#ebf0f7',
            '#ebf0f7',
            '#ebf0f7',
            '#ebf0f7',
          ],
          plotOptions: {
            bar: {
              columnWidth: '80%',
              distributed: true,
              borderRadius:3,
              // endingShape: 'rounded',
            },
          },
          tooltip: {
            x: { show: true },
          },
          xaxis: {
            categories: ['Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat', 'Sun',],
            labels: {
                style: {
                    colors: '#6E6B7B',
                    fontSize: '0.86rem',
                    fontFamily: 'Montserrat',
                },
                show:true,
            },
          },
        },
        type:'Weekly',
        invoices:{
          total:0,
          refunded:0,
          unpaid:0,
          paid:0,
        },
        loading:false
      }
    },
    watch:{
      type(){
        if(this.type=='Weekly'){
          this.$refs.myChart.updateOptions({
            xaxis: {
              categories: ['Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat', 'Sun',]
            }
          })
          }else{
            this.$refs.myChart.updateOptions({
              xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
              }
            })
        }
      }
    },
    mounted(){
      this.getReport()
    },
    methods: {
      getReport(){
        this.loading=true
        get_invoice_reports({timeframe:this.type.toLowerCase()}).then(resp=>{
          this.loading=false
          this.invoices=resp.data
          this.setInvoicesGraph(resp.data.invoicesCounts)
        })
      },
      setInvoicesGraph(data){
        let reportData=[]
        data.map(r=>{
          reportData.push(r.count)
        })
        this.$refs.myChart.updateSeries([{data:reportData}])
      },
      getMonthlyReport(){
        this.type='Monthly'
        this.getReport()
      },
      getWeeklyReport(){
        this.type='Weekly'
        this.getReport()
      },
    }
  }
  </script>
  <style scoped>
  .heading-text{
    font-size: 38px;
    font-weight: 600;
    line-height:52px;
    color: #4B465C;
    font-family: 'Montserrat', sans-serif;
  }
  .sub-heading{
    font-size: 18px;
   font-weight: 600;
   line-height: 24px;
   color: #4B465C;
  }
  .text-simple{
    font-size: 13px;
    font-weight: 400;
    line-height:20px;
    color: #6E6B7B;
  }
  .amount-text{
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    color: #4B465C;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
  .label-text{
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    color: #4B465C;
     margin:0px 6px;
   }
   .my-8{
    margin: 8px 0px;
   }
  </style>
  
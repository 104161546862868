<template>
  <b-card
    v-if="data"
    no-body
  >
  <b-overlay
    :show="loading"
    rounded
    opacity="0.6"
    spinner-variant="primary"
  >
  <b-card-header>
    <div>
        <b-card-title class="sub-heading">{{ data.title }}</b-card-title>
        <b-card-text class="font-small-2">
          Last {{ type=='Weekly'?'7':'30' }} Days
        </b-card-text>
      </div>

      <div>
        <b-dropdown
        :text="type"
        variant="outline-secondary"
      >
      <b-dropdown-item href="#" @click="()=>getMonthlyReport()">
        Monthly
      </b-dropdown-item>
      <b-dropdown-item href="#" @click="()=>getWeeklyReport()">
        Weekly
      </b-dropdown-item>
      </b-dropdown>
      </div>
  </b-card-header>

    <b-card-body>
      <div class="row">
        <div class="col-6">
          <h1 class="font-large-2 font-weight-bolder mb-0">
            {{ supportTrackerData.total }}
          </h1>
          <small>Tickets</small>
         
          
          <div class="my-1">
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  rounded
                  size="42"
                  :variant="`light-info`"
                >
                  <feather-icon
                    size="22"
                    icon="CheckCircleIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="label-text">
                  Open Tickets
                </h6>
                <small class="text-simple">{{supportTrackerData.open}}</small>
              </b-media-body>
            </b-media>
          </div>
          <div class="my-1">
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  rounded
                  size="42"
                  :variant="`light-primary`"
                >
                  <feather-icon
                    size="22"
                    color="primary"
                    icon="TagIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="label-text">
                  Closed Tickets
                </h6>
                <small class="text-simple">{{supportTrackerData.closed}}</small>
              </b-media-body>
            </b-media>
          </div>
          <div class="my-1">
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  rounded
                  size="42"
                  :variant="`light-warning`"
                >
                  <feather-icon
                    size="22"
                    icon="ClockIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="label-text">
                  Average Response Time
                </h6>
                <small class="text-simple">{{supportTrackerData.averageResponseTime}}h</small>
              </b-media-body>
            </b-media>
          </div>
        </div>
        <div class="col-6">
          <vue-apex-charts v-show="supportTrackerData.total>0"
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptions"
            :series="supportTrackerData.supportTrackerRadialBar.series"
            ref="supportChart"
          />
        </div>
      </div>
    </b-card-body>
  </b-overlay>
  </b-card>
</template>

<script>
import {
 BOverlay, BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol, BCardText,BAvatar, BMedia, BMediaAside, BMediaBody
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import moment from 'moment'
import {get_support_report} from '@/apis/admin/dashboard'
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BAvatar, 
    BMedia, 
    BMediaAside, 
    BMediaBody,
    BOverlay
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.primary],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.light],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Completed Tickets'],

        },
      },
      supportTrackerData:{
        series: [],
        averageResponseTime:0,
        closed:0,
        open:0,
        total:0,
        supportTrackerRadialBar: {
          series: [50],
        },
      },
      type:'Weekly',
      loading: false,
    }
  },
  mounted(){
    this.getSupportReport()
  },
  methods: {
    getSupportReport(){
      this.loading=true;
      get_support_report({timeframe:this.type.toLowerCase()}).then(resp => {
        this.loading=false;
        this.supportTrackerData = {...this.supportTrackerData, ...resp.data}
        this.supportTrackerData.averageResponseTime=moment.duration(resp.data.averageResponseTime).hours();
        this.$refs.supportChart.updateSeries([((resp.data.total-resp.data.open)/resp.data.total*100).toFixed(2)])
      }).catch(err=>{
        this.loading=false;
      })
    },
    getMonthlyReport(){
        this.type='Monthly'
        this.getSupportReport()
      },
      getWeeklyReport(){
        this.type='Weekly'
        this.getSupportReport()
      },
  },
}
</script>
<style scoped>
.sub-heading{
  font-size: 18px;
 /*font-weight: 600;*/
 line-height: 24px;
 color: #4B465C;
}
.label-text{
  font-size: 15px;
  /*font-weight: 600;*/
  line-height: 22px;
  color: #4B465C;
  margin: 0px;
 }
 .text-simple{
  font-size: 13px;
  /*font-weight: 400;*/
  line-height:20px;
  color: #6E6B7B;
}
</style>

import { render, staticRenderFns } from "./SupportTracker.vue?vue&type=template&id=0448e966&scoped=true"
import script from "./SupportTracker.vue?vue&type=script&lang=js"
export * from "./SupportTracker.vue?vue&type=script&lang=js"
import style0 from "./SupportTracker.vue?vue&type=style&index=0&id=0448e966&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0448e966",
  null
  
)

export default component.exports
<template>
  <b-card no-body>
    <b-overlay
      :show="loading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
    >
    <b-card-body class="pb-0">
      <div class="d-flex justify-content-between mb-auto">
        <div >
          <b-avatar
            class="mb-1"
            :variant="`light-success`"
            size="45"
          >
            <feather-icon
              size="21"
              icon="CreditCardIcon"
            />
          </b-avatar>
          <div class="truncate">
            <h2 class="mb-25 font-weight-bolder">
              {{revenueGenerated | currency}}
            </h2>
            <span>Revenue Generated</span>
          </div>
        </div>
        <div>
          <b-dropdown
          :text="type"
          variant="outline-secondary"
        >
          <b-dropdown-item href="#" @click="()=>getMonthlyReport()">
            Monthly
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="()=>getWeeklyReport()">
            Weekly
          </b-dropdown-item>
        </b-dropdown>
        </div>
      </div>
      
      
      <data class="">
        <vue-apex-charts
          type="area"
          height="100"
          width="100%"
          
          ref="myChart"
          :options="chartOptionsComputed"
          :series="revenueGained.series"
        />
      </data>
      
    </b-card-body>

    
  </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar, BOverlay,  BDropdown, BDropdownItem,} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import {get_revenue_report} from '@/apis/admin/dashboard'
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardBody,
    BAvatar,
    BOverlay,
    BDropdown, 
    BDropdownItem,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: null,
    },
  },
  data(){
      return {
          areaChartOptions:{
              grid: {
                  show: false,
                  padding: {
                  left: 0,
                  right: 0,
                  },
              },
              chart: {
                  toolbar: {
                  show: false,
                  },
                  sparkline: {
                  enabled: true,
                  },
              },
              dataLabels: {
                  enabled: false,
              },
              stroke: {
                  curve: 'smooth',
                  width: 2.5,
              },
              fill: {
                  type: 'gradient',
                  gradient: {
                  shadeIntensity: 0.9,
                  opacityFrom: 0.5,
                  opacityTo: 0.2,
                  stops: [0, 80, 100],
                  },
              },
              yaxis: [
                  {
                  y: 0,
                  offsetX: 0,
                  offsetY: 0,
                  padding: {
                      left: 0,
                      right: 0,
                  },
                  },
              ],
              tooltip: {
                  x: { show: true },
              },
              xaxis: {
                categories: ['Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat', 'Sun',],
              },
              theme: {
                  monochrome: {
                  enabled: true,
                  color: $themeColors.primary,
                  shadeTo: 'light',
                  shadeIntensity: 0.65,
                  },
              },
            },
          revenueGained: {
              series: [
              {

                  name: 'Revenue',
                  data: [],
              },
              ],
              analyticsData: {
              subscribers: 92600,
              },
          },
          revenueGenerated:0,
          type:'Weekly',
          loading:false
      }
  },
  computed: {
    chartOptionsComputed() {
      if (this.chartOptions === null) {
        const options = JSON.parse(JSON.stringify(this.areaChartOptions))
        options.theme.monochrome.color = $themeColors['primary']
        return options
      }
      return this.chartOptions
    },
  },
  mounted(){
    this.getRevenueReport()
  },
  watch:{
    type(){
      if(this.type=='Weekly'){
        this.$refs.myChart.updateOptions({
          xaxis: {
            categories: ['Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat', 'Sun',]
          }
        })
        }else{
          this.$refs.myChart.updateOptions({
            xaxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            }
          })
      }
    }
  },
  methods:{
    getRevenueReport(){
      let reportData=[]
      this.loading=true
      get_revenue_report({timeframe:this.type.toLowerCase()}).then(res => {
        this.loading=false
        res.data.map(r=>{
          reportData.push(r.revenue)
        })
        this.$refs.myChart.updateSeries([{data:reportData}])
        this.revenueGenerated=reportData.reduce((a, b) => a + b, 0)/1000
      }).catch(err=>{
        this.loading=false
      })
      
    },
    getMonthlyReport(){
        this.type='Monthly'
        this.getRevenueReport()
      },
      getWeeklyReport(){
        this.type='Weekly'
        this.getRevenueReport()
      }
  }
}
</script>
<style scoped>
.chart-div{
  position: absolute;
  bottom: -5px;
}
</style>

  
<template>
    <div>
        <div class="row">
            <div class="col-lg-6 col-12 mb-2">
                <div class="row">
                    <div class="col-12">
                        <sales-overview></sales-overview>
                    </div>
                    <div class="col-12">
                        <subscription-reports class="h-100"/>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12 mb-2">
                <revenue-generated class="h-100"></revenue-generated>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-12">
                <earnings-report :data="data.avgSessions" />
            </div>
            <div class="col-lg-6 col-12">
                <support-tracker :data="data.supportTracker"/>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-12">
                <total-earnings/>
            </div>
            <div class="col-lg-6 col-12">
                <invoice-reports/>
            </div>
        </div>
    </div>
</template>
<script>
import EarningsReport from "@/components/functional-components/admin/reports/EarningsReport.vue"
import SupportTracker from "@/components/functional-components/admin/reports/SupportTracker.vue"
import TotalEarnings from '@/components/functional-components/admin/reports/TotalEarnings.vue'
import SalesOverview from '@/components/functional-components/admin/reports/SalesOverview.vue'
import RevenueGenerated from '@/components/functional-components/admin/reports/RevenueGenerated.vue'
import SubscriptionReports from '@/components/functional-components/admin/reports/SubscriptionReports.vue'
import InvoiceReports from '@/components/functional-components/admin/reports/InvoiceReports.vue'
// import AnalyticsTimeline from './AnalyticsTimeline.vue'
import { kFormatter } from '@core/utils/filter'
export default{
    components:{
        EarningsReport,
        SupportTracker,
        TotalEarnings,
        SalesOverview,
        RevenueGenerated,
        SubscriptionReports,
        InvoiceReports
    },
    data() {
        return {
        data: {},
        }
    },
    created() {
        // data
        this.$http.get('/analytics/data')
        .then(response => { this.data = response.data 
            console.log('rev:',response.data)
        })
    },
    methods: {
        kFormatter,
    },
}
</script>
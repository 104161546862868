<template>
    <b-card
      class="card-transaction"
      no-body
    >
    <b-card-header>
      <div>
          <b-card-title class="sub-heading">Sales</b-card-title>
          <b-card-text class="font-small-2">
            Yearly Sales Overview
          </b-card-text>
        </div>

      <b-dropdown
        :text="type"
        variant="outline-secondary"
      >
        <b-dropdown-item href="#" @click="()=>getMonthlyReport()">
          Monthly
        </b-dropdown-item>
        <b-dropdown-item href="#" @click="()=>getWeeklyReport()">
          Weekly
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
  
      <b-card-body>
        <div class="d-flex justify-content-between">
          <div>
            <span class="text-small">Sales Overview</span><br>
            <span class="heading-text">{{subscriptionDetails.totalSale | currency}}</span>
        </div>

        <!-- <div class="text-success text-simple">+{{percentChange}}%</div> -->
    </div>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardTitle,BCardText, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,BProgress
  } from 'bootstrap-vue'
  import {get_subscription_report} from '@/apis/admin/dashboard'
  export default {
    components: {
      BCard,
      BCardHeader,
      BCardTitle,
      BCardText,
      BCardBody,
      BMediaBody,
      BMedia,
      BMediaAside,
      BAvatar,
      BDropdown,
      BDropdownItem,
      BProgress
    },
    data(){
      return {
        subscriptionDetails:{
          activeFreeTierUsers:0,
          activePaidTierUsers:0,
          activeUsers:0,
          percentChange:0,
          totalSale:0
        },
        loading: false,
        type:'Monthly',
      }
    },
    mounted() {
      this.getSubscriptionReports()
    },
    methods:{
      getSubscriptionReports(){
        this.loading=true
        get_subscription_report({timeline:this.type.toLowerCase()}).then(resp=>{
          this.loading=false
          this.subscriptionDetails=resp.data
        }).catch(err=>{
          this.loading=false
        })
      },
      getPercentage(value, total){
        return (value/total*100).toFixed(1)
      },
      getMonthlyReport(){
        this.type='Monthly'
        this.getSubscriptionReports()
      },
      getWeeklyReport(){
        this.type='Weekly'
        this.getSubscriptionReports()
      }
    }
  }
</script>
<style scoped>
.text-small{
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #4B465C;
  opacity: .5;
  
}
.heading-text{
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  color: #4B465C;
}
.text-simple{
 font-size: 15px;
 font-weight: 400;
 line-height: 22px;
 color: #4B465C;
  margin:0px 6px;
}
.sub-heading{
  font-size: 18px;
 font-weight: 600;
 line-height: 24px;
 color: #4B465C;
}
.line{
  width:1px;
  background-color: #4B465C;
  opacity:.3;
}
</style>
  